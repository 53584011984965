import React, { useState } from "react";
import styled from "styled-components";
import "typeface-libre-baskerville";
import "typeface-roboto";

import logo from "./img/femegades-logo.png";
import track from "./utils/track";

import regentSt from "./img/regent-street-records-logo.png";

const followMenu = [
  { type: "Facebook", url: "https://facebook.com/femegades" },
  { type: "Twitter", url: "https://twitter.com/femegades" },
  { type: "Instagram", url: "https://www.instagram.com/femegades/" },
];

const listenMenu = [
  {
    type: "Spotify",
    url: "https://open.spotify.com/artist/6ElQLcTpCrv2utVIQcPy5v",
  },
  { type: "Bandcamp", url: "https://femegades.bandcamp.com/" },
  { type: "Soundcloud", url: "https://soundcloud.com/femegades" },
  {
    type: "iTunes",
    url: "https://music.apple.com/us/artist/femegades/1535275924",
  },
];

const Header = ({ tracking }) => {
  const [secondaryMenu, setSecondaryMenu] = useState();

  return (
    <HeaderContainer>
      <HeaderText>
        <h1>
          <a href="/">femegades</a>
        </h1>
      </HeaderText>
      <Links>
        <TopLevelLinkItem
          onClick={() => {
            if (secondaryMenu !== "follow") {
              setSecondaryMenu("follow");
            } else {
              setSecondaryMenu(undefined);
            }
          }}
        >
          Follow <Arrow />
        </TopLevelLinkItem>
        <TopLevelLinkItem
          onClick={() => {
            if (secondaryMenu !== "listen") {
              setSecondaryMenu("listen");
            } else {
              setSecondaryMenu(undefined);
            }
          }}
        >
          Listen <Arrow />
        </TopLevelLinkItem>
        <TopLevelLinkItem>
          <a href="https://www.youtube.com/@femegades/videos">Videos</a>
        </TopLevelLinkItem>
        <TopLevelLinkItem>
          <a href="/live">live</a>
        </TopLevelLinkItem>
        <TopLevelLinkItem>
          <a
            onClick={() => track(tracking, "Bandcamp")}
            href="https://femegades.bandcamp.com/merch"
          >
            store
          </a>
        </TopLevelLinkItem>
        <TopLevelLinkItem>
          <a href="http://www.abadgeoffriendship.com/femegades">press</a>
        </TopLevelLinkItem>
        <TopLevelLinkItem>
          <a
            onClick={() => track(tracking, "Mailto")}
            href="mailto:femegades@gmail.com"
          >
            contact
          </a>
        </TopLevelLinkItem>
        <Subscribe>
          <a href="/subscribe">Subscribe</a>
        </Subscribe>
      </Links>
      <Links>
        {secondaryMenu === "follow" &&
          followMenu.map(({ type, url }) => (
            <SecondLevelLinkItem>
              <a onClick={() => track(tracking, type)} href={url}>
                {type}
              </a>
            </SecondLevelLinkItem>
          ))}

        {secondaryMenu === "listen" &&
          listenMenu.map(({ type, url }) => (
            <SecondLevelLinkItem>
              <a onClick={() => track(tracking, type)} href={url}>
                {type}
              </a>
            </SecondLevelLinkItem>
          ))}
      </Links>
      <NAM>
        <a
          title="listen to new EP Sex Robots"
          href="https://open.spotify.com/album/6EVvQwsLuqV8nSpYScqTt7"
        >
          New EP 'Sex Robots'... out NOW!
        </a>
      </NAM>
      <RegentStreetLogo
        href="https://www.regentstreetrecords.com/"
        target="_blank"
        onClick={() => track(tracking, "RegentSt")}
      />
    </HeaderContainer>
  );
};

// h: 264px / 206px
const HeaderContainer = styled.div`
  height: auto;
  background-color: #1b1b1b;
  text-align: center;
  position: relative;
`;

const HeaderText = styled.div`
  font-family: Libre Baskerville;
  font-size: 48px;
  color: white;
  font-weight: bold;
  padding: 22px 0 16px;

  h1 {
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 60px;
    text-indent: -9999px;
    margin: 0;

    a {
      display: block;
      margin: 0 auto;
      width: 400px;
      height: 100%;
      text-decoration: none;
      color: white;
    }
  }
`;

const Links = styled.ul`
  color: white;
  background-color: #1b1b1b;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: auto;
  flex-wrap: wrap;
  border-top: 1px solid #242424;
  width: 96%;
  max-width: 1323px;
`;

const TopLevelLinkItem = styled.li`
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  margin: 8px 0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;

  a {
    color: white;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

const Arrow = styled.span`
  content: "";
  width: 0;
  height: 0;
  font-size: 0;
  border-bottom: 0 none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  margin: 5px 0 0 5px;
`;

const Subscribe = styled(TopLevelLinkItem)`
  background-color: #ffa31a;
  padding: 8px;
  text-transform: none;

  a {
    color: black;
  }
`;

const SecondLevelLinkItem = styled.li`
  display: inline-block;
  list-style-type: none;
  padding: 8px;
  margin: 8px 0;

  a {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    text-decoration: none;

    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`;

const NAM = styled.span`
  display: flex;
  justify-content: center;
  background-color: #ffa31a;
  color: black;
  padding: 10px 0;
  width: 100%;
  font-weight: bold;
  font-size: 22px;

  a {
    text-decoration: none;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const RegentStreetLogo = styled.a`
  background-image: url(${regentSt});
  background-size: contain;
  height: 100px;
  width: 100px;
  position: absolute;
  top: -10px;
  right: 0;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    height: 110px;
    width: 140px;
    top: -14px;
    right: 16px;
  }
`;

export default Header;
